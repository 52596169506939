function PrivacyPolicy() {
  return (
    <div className="w-screen  bg-white pointer-events-none">
      <div className="flex flex-col justify-center items-center space-y-8  mt-14 xl:mt-0 2xl:mt-12 p-2">
        <p className="flex justify-center long-title text-center text-8xl p-4 xl:p-0 space-x-2">
        Privacy Policy
        </p>

        <div className="space-y-3">
          <div>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            This privacy policy (the "Privacy Policy") describes how your personal information is collected, used, and shared when you visit or interact with familylyx.com (the "Website") or use any services offered by Family, Inc. ("Family", "we", "our", "us").
            </p>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            By accessing or using the Website, you consent to the information collection, usage, and data handling practices outlined in this Privacy Policy.
            </p>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            Family operates from Australia and complies with the Australian Privacy Principles (APPs) under the Privacy Act 1988 (Cth). We also recognize the rights of our overseas customers and are committed to respecting their privacy rights as well.
            </p>
            
          </div>
        </div>
        
        <div className="space-y-3">
          <div>
            <p className="flex justify-center long-title text-center text-gray-500 max-w-5xl text-5xl p-4 xl:p-0 space-x-2">
            Data Collection
            </p>
          </div>
          <div>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            We collect information in line with the APPs, which includes but is not limited to:
            </p>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            - Location data
            </p>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            - Demographic data
            </p>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            - Transaction information
            </p>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            - Usage data
            </p>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            - Device data
            </p>
          </div>
        </div>
        
        
        <div className="space-y-3">
          <div>
            <p className="flex justify-center long-title text-center text-gray-500 max-w-5xl text-5xl p-4 xl:p-0 space-x-2">
            Data Usage
            </p>
          </div>
          <div>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            We use or disclose personal information only for the purpose it was collected, unless you have consented otherwise, or an exception applies. This could include:
            </p>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            - Enhancing the safety and security of our users and services
            </p>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            - Research and development purposes
            </p>
      
          </div>
        </div>

        
        <div className="space-y-3">
          <div>
            <p className="flex justify-center long-title text-center text-gray-500 max-w-5xl text-5xl p-4 xl:p-0 space-x-2">
            Data Retention
            </p>
          </div>
          <div>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            Family retains personal data for our records, unless you request deletion. We implement measures to protect personal information from misuse, interference, and loss, as well as unauthorised access, modification, or disclosure.
            </p>
            
          </div>
        </div>

        
        <div className="space-y-3">
          <div>
            <p className="flex justify-center long-title text-center text-gray-500 max-w-5xl text-5xl p-4 xl:p-0 space-x-2">
            Cross-Border Disclosure of Personal Information
            </p>
          </div>
          <div>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            While we operate primarily in Australia, we may disclose personal information to overseas recipients in order to provide our services. Before we disclose personal information to an overseas recipient, we take reasonable steps to ensure that the recipient will not breach the APPss.
            </p>
          </div>
        </div>

        
        <div className="space-y-3">
          <div>
            <p className="flex justify-center long-title text-center text-gray-500 max-w-5xl text-5xl p-4 xl:p-0 space-x-2">
            Access to Personal Information
            </p>
          </div>
          <div>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            If we hold personal information about you, you have the right to request access to this information.
            </p>
            
          </div>
        </div>

        
        <div className="space-y-3">
          <div>
            <p className="flex justify-center long-title text-center text-gray-500 max-w-5xl text-5xl p-4 xl:p-0 space-x-2">
            Correction of Personal Information
            </p>
          </div>
          <div>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            Upon your request, we will take reasonable steps to correct any personal information we hold about you.
            </p>
          </div>
        </div> 

        
        <div className="space-y-3">
          <div>
            <p className="flex justify-center long-title text-center text-gray-500 max-w-5xl text-5xl p-4 xl:p-0 space-x-2">
            Your Privacy Rights
            </p>
          </div>
          <div>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            Whether you are from Australia or an overseas country, you have the right to request copies of the data we have collected about you, request that your personal information be deleted, or exercise your right of access or correction. To make any of these requests, please contact us at <a className="text-blue-600 hover:text-blue-800" href="mailto:support@familylyx.com">support@familylyx.com</a>.
            </p>
            
          </div>
        </div>

        <div className="space-y-3">
          <div>
            <p className="flex justify-center long-title text-center text-gray-500 max-w-5xl text-5xl p-4 xl:p-0 space-x-2">
            Changes
            </p>
          </div>
          <div>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            We may update this privacy policy from time to time to reflect changes to our practices or for other operational, legal, or regulatory reasons. We will post any changes on the Website as soon as they go into effect.
            </p>
          </div>
        </div>

        
        <div className="space-y-3">
          <div>
            <p className="flex justify-center long-title text-center text-gray-500 max-w-5xl text-5xl p-4 xl:p-0 space-x-2">
            Definitions
            </p>
          </div>
          <div>
            <p className="text-center text-gray-600 xl:text-base max-w-5xl mx-auto">
            "Personal Information" is information that can identify or contact you, such as your name, email address, and any other information that you provide in the course of accessing and using the Website, including any data about your use of the services, which may be collected automatically.
            </p>
          </div>
        </div>
        
      </div>
    </div>
  )
}

export default PrivacyPolicy